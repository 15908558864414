import axios, { AxiosError, AxiosResponse } from 'axios';
import { observable, makeObservable, action, computed } from 'mobx';
import { IPrice } from './types';
import { MainStore } from './MainStore';
import { AvailabilityState } from '../components/orderModal/deliveryTime/deliveryTime';
import defaultStyles from '../styles/defaults.scss';
import * as helper from '../helper/html-helper';

function getWithVatValue(priceModeCookie?: string) {
    let result;

    switch (priceModeCookie) {
        case 'Gross':
            result = true;
            break;
        case 'Net':
            result = false;
            break;
        default:
            result =
                typeof document !== 'undefined'
                    ? document.getElementsByClassName('current-pricemode-gross').length > 0
                    : true;
    }

    return result;
}

class OrderModuleStore {
    mainStore: MainStore;
    itemOrigin: string;
    withVat: boolean;
    compareShareBookmarkMobileExpanded: boolean;
    articleCount: number | '';
    stickHeaderHeight = 0;
    omShippingTextContainer: Element;
    omShippingTextElement: Element;
    extContentContainer: Element;
    extContentElement: Element;
    private readonly esBrand = 'engelbert strauss';

    constructor(store: MainStore, itemOrigin: string, contentContainerId?: string) {
        this.mainStore = store;
        this.withVat = getWithVatValue(localStorage.getItem('PriceMode'));
        this.articleCount = 1;
        this.compareShareBookmarkMobileExpanded = false;
        this.omShippingTextContainer = document.getElementById('om-shippingtext-link');
        this.extContentContainer = contentContainerId ? document.getElementById(contentContainerId) : null;
        this.itemOrigin = itemOrigin;

        makeObservable(this, {
            withVat: observable,
            articleCount: observable,
            stickHeaderHeight: observable,
            compareShareBookmarkMobileExpanded: observable,
            toggleWithVat: action.bound,
            setPriceModeCookie: action.bound,
            setArticleCount: action.bound,
            setStickHeaderHeight: action.bound,
            toggleCompareShareBookmarkMobileExpanded: action.bound,
            basePriceString: computed,
            infoTexts: computed,
            articleUnit: computed,
            basketModalData: computed,
            titleAddon: computed,
            deliveryTimeString: computed,
            deliveryTimeStatusColor: computed,
            isSale: computed,
            shouldRenderBasePrice: computed,
            shouldRenderInfoTexts: computed,
        });

        // normal methods that don't change state
        this.getPriceString = this.getPriceString.bind(this);
        this.updateBasketItemCount = this.updateBasketItemCount.bind(this);
        this.onPriceModeChangedEvent = this.onPriceModeChangedEvent.bind(this);
        this.getShippingTextElement = this.getShippingTextElement.bind(this);
        this.returnShippingTextElement = this.returnShippingTextElement.bind(this);
        this.getExtContentElement = this.getExtContentElement.bind(this);
        this.returnExtContentElement = this.returnExtContentElement.bind(this);
        this.handleShareClick = this.handleShareClick.bind(this);

        if (typeof window !== 'undefined') {
            window.shell.subscribeTo(
                'PriceModeChanged',
                (newPriceMode) => {
                    this.onPriceModeChangedEvent(newPriceMode);
                },
                ''
            );
        }
        
    }

    getPriceString(price?: IPrice): string {
        if (price) {
            let localizedPriceString: string;
            let priceString: string;
            if (this.mainStore.isGlobal) {
                priceString = price.netValue.toFixed(2);
            } else {
                priceString = (this.withVat ? price.grossValue : price.netValue).toFixed(2);
            }

            const priceStringSplit = priceString.split('.');

            let intString = priceStringSplit[0];
            let seperatedIntString = '';
            const numberGroupSeparator =  this.mainStore.dataStore.contextData.numberFormat.numberGroupSeparator;

            while (intString.length > 0) {
                if (intString.length > 3) {
                    const last3Digits = intString.slice(-3);
                    seperatedIntString =  numberGroupSeparator + last3Digits + seperatedIntString;
                } else {
                    seperatedIntString = intString + seperatedIntString;
                }
                intString = intString.slice(0, -3);
            }

            const numberDecimalSeparator =  this.mainStore.dataStore.contextData.numberFormat.numberDecimalSeparator;
            const fractionString = priceStringSplit[1];

            localizedPriceString = `${seperatedIntString}${numberDecimalSeparator}${fractionString}`;

            const currency = this.mainStore.dataStore.orderModule.articlePrice.currency.symbolShortForm;
            const prependCurrencySymbol =
                this.mainStore.dataStore.orderModule.articlePrice.currency.prependCurrencySymbol;

            if (prependCurrencySymbol)
                localizedPriceString = `${currency} ${localizedPriceString}`;
            else
                localizedPriceString = `${localizedPriceString} ${currency}`
            return localizedPriceString;
        } else {
            return '';
        }
    }

    updateBasketItemCount() {
        const savKey = this.mainStore.dataStore.orderModule.articleInfo.salesArticleVariantKey;
        this.mainStore.fetcher
            .getBasketItemCount(savKey, (itemCount) => {
                this.setArticleCount(itemCount);
            }, () => {
                this.setArticleCount(1);
            })
    }

    onPriceModeChangedEvent(newPriceMode: 'Gross' | 'Net') {
        // update withVat if it is out of sync with app shell event value
        if ((newPriceMode === 'Gross' && !this.withVat) || (newPriceMode === 'Net' && this.withVat)) {
            this.toggleWithVat();
        }
    }

    toggleWithVat = () => {
        this.withVat = !this.withVat;
    };

    setPriceModeCookie(newPriceMode: 'Net' | 'Gross') {
        const urlToHost = this.mainStore.fetcher.magicLink.replace('api/', '');
        const apiUrl = urlToHost + 'ajax/update/Content/Empty2';
        const requestURL = new URL(apiUrl);

        requestURL.searchParams.set('PriceMode', newPriceMode);
        axios
            .get(requestURL.toString())
            .then((res: AxiosResponse) => {
                if (res.status === 200 && typeof window !== 'undefined') {
                    window.shell.publishTo('PriceModeChanged', newPriceMode);
                }
            })
            .catch((error: Error | AxiosError) => {
                const errorMsg = `${error.name}: ${error.message}`;
                const errorStack = error.stack;

                // eslint-disable-next-line no-console
                console.warn(`fetch() failed with "${errorMsg}". Full error:\n${errorStack}`);
            });
    }

    setArticleCount(param: number | ''): void {
        this.articleCount = param;
    }

    setStickHeaderHeight(param: number): void {
        this.stickHeaderHeight = param;
    }

    toggleCompareShareBookmarkMobileExpanded(): void {
        this.compareShareBookmarkMobileExpanded = !this.compareShareBookmarkMobileExpanded;
    }

    getShippingTextElement(): void {
        if (this.omShippingTextContainer) {
            this.omShippingTextElement = this.omShippingTextContainer.children[0];
        }
    }

    returnShippingTextElement(): void {
        if (this.omShippingTextElement) {
            this.omShippingTextContainer.appendChild(this.omShippingTextElement);
        }
    }

    getExtContentElement(): void {
        if (this.extContentContainer) {
            this.extContentElement = this.extContentContainer.children[0];
        }
    }

    returnExtContentElement(): void {
        if (this.extContentElement) {
            this.extContentContainer.appendChild(this.extContentElement);
        }
    }

    handleShareClick(): void {
        const generalL10n = this.mainStore?.localization
        const articleTitle = this.mainStore?.dataStore.orderModule.articleInfo.title;
        const detailsPageUrl = new URL(this.mainStore?.dataStore.contextData.detailsPageUrl.toLowerCase()).toString();

        if (navigator.share) {
            navigator.share({
                title: generalL10n.shareLinkText.replace(
                    '{0}',
                    articleTitle ?? ''
                ),
                text: generalL10n.shareLinkBody
                    .replace('{0}', articleTitle ?? '')
                    .replace('{1}', generalL10n.companyNameSimple),
                url: detailsPageUrl,
            });
        } else {
            window.location.href =
                'mailto:?subject=' +
                encodeURIComponent(
                    generalL10n.shareLinkText.replace('{0}', articleTitle ?? '')
                ) +
                '&body=' +
                encodeURIComponent(
                    generalL10n.shareLinkBody
                        .replace('{0}', articleTitle ?? '')
                        .replace('{1}', generalL10n.companyNameSimple)
                ) +
                ' ' +
                detailsPageUrl;
        }
    }

    get basePriceString() {
        let result = '';
        const basicPrice = this.mainStore.dataStore.orderModule.articlePrice.basicPrice;
        if (basicPrice.basePrice) {
            const priceString = this.getPriceString({
                grossValue: basicPrice.basePrice.grossValue,
                netValue: basicPrice.basePrice.netValue,
            });
            result = priceString;
        }
        return [result, basicPrice.basePrice.unit];
    }

    get infoTexts() {
        let infoTextResult: string[][] = [];
        const { priceFromLocale } = this.mainStore.localization;
        const { articlePrice, articleInfo } = this.mainStore.dataStore.orderModule;
        if (Array.isArray(articlePrice.scalePrices)) {
            infoTextResult = articlePrice.scalePrices.map((item) => {
                const unit =
                    item.quantity > 1
                        ? articleInfo.packagingInformation.quantityUnit.plural
                        : articleInfo.packagingInformation.quantityUnit.singular;
                const preString = `${priceFromLocale} ${item.quantity} ${unit}: `;
                let basePricePreString = '';
                let basePriceString = '';
                let basePriceUnit = '';

                if (item.price.basePrice) {
                    basePriceUnit = item.price.basePrice.unit ? `/${item.price.basePrice.unit}` : '';
                    basePriceString = this.getPriceString(item.price.basePrice);
                    basePricePreString = ` | ${this.mainStore.localization.basePriceLocale}: `;
                }

                return [preString, this.getPriceString(item.price),
                    basePricePreString, basePriceString, basePriceUnit];
            });
        }
        return infoTextResult;
    }

    get titleAddon(): string {
        let result = '';

        const packagingInformation = this.mainStore.dataStore.orderModule.articleInfo.packagingInformation;
        if (packagingInformation.packageSize > 1) {
            let quantity = packagingInformation.quantityUnit.plural;
            let subQuantityUnit = '';
            if (packagingInformation.subQuantityUnit) {
                subQuantityUnit = `${packagingInformation.subQuantityUnit.plural} / `;
                quantity = packagingInformation.quantityUnit.singular;
            }
            result = `${packagingInformation.packageSize} ${subQuantityUnit}${quantity}`;
        }

        return result;
    }

    get deliveryTimeString(): string {
        const {
            periodType,
            minValue,
            maxValue,
            calendarWeek,
        } = this.mainStore.dataStore.orderModule.deliveryInformation;

        let deliveryTimeLocale;
        let result;
        if (!this.mainStore.isGlobal) {
            switch (periodType) {
                case 1:
                    deliveryTimeLocale = this.mainStore.localization.deliveryTimeUnknownDateLocale;
                    break;
                case 2:
                    deliveryTimeLocale = this.mainStore.localization.deliveryTimeFromCalWeekLocale;
                    break;
                default:
                    deliveryTimeLocale = this.mainStore.localization.deliveryTimeKnownDateLocale;
            }

            if (typeof window != 'undefined') {
                result =
                    periodType === 2
                        ? helper.decodeHTML(deliveryTimeLocale) + ' ' + String(calendarWeek)
                        : helper
                            .decodeHTML(deliveryTimeLocale)
                            ?.replace('{0}', String(minValue))
                            .replace('{1}', String(maxValue));
            } else {
                result =
                    periodType === 2
                        ? deliveryTimeLocale + String(calendarWeek)
                        : `~~~${deliveryTimeLocale}~${String(minValue)}~${String(maxValue)}~~~`;
            }
        } else {
            switch (this.mainStore.dataStore.orderModule.availabilityState) {
                case AvailabilityState.InStock:
                    deliveryTimeLocale = this.mainStore.localization.deliveryDateShortGLBLocale;
                    break;
                case AvailabilityState.SoonInStock:
                    deliveryTimeLocale = this.mainStore.localization.deliveryDateLongGLBLocale;
                    break;
                default:
                    deliveryTimeLocale = this.mainStore.localization.deliveryDateLongGLBLocale;
            }
            result = helper.decodeHTML(deliveryTimeLocale);
        }
        return result;
    }

    get deliveryTimeStatusColor(): string {
        let statusColor;

        switch (this.mainStore.dataStore.orderModule.availabilityState) {
            case AvailabilityState.InStock:
                statusColor = defaultStyles.dpGreen;
                break;
            case AvailabilityState.SoonInStock:
                statusColor = defaultStyles.dpOrange;
                break;
            default:
                statusColor = defaultStyles.dpRed;
        }

        return statusColor;
    }

    get isSale(): boolean {
        return !!this.mainStore.dataStore.orderModule.articlePrice.minimalOriginalPrice;
    }

    get shouldRenderBasePrice(): boolean {
        return !!this.mainStore.dataStore.orderModule.articlePrice.basicPrice.basePrice;
    }

    get shouldRenderInfoTexts() {
        return (
            !this.mainStore.dataStore.orderModule.articlePrice.hideScalePrices &&
            !(!this.infoTexts || this.infoTexts.length === 0)
        );
    }

    get articleUnit() {
        const packagingInformation = this.mainStore.dataStore.orderModule.articleInfo.packagingInformation;

        return this.articleCount > 1
            ? packagingInformation.quantityUnit.plural
            : packagingInformation.quantityUnit.singular;
    }

    get basketModalData(): IBasketModal {
        const articleCount =
            typeof this.mainStore.orderModuleStore.articleCount === 'number'
                ? this.mainStore.orderModuleStore.articleCount
                : 0;
        const basketModalItemOrigin = this.itemOrigin.length > 0 ? 'MODAL_ORDER/' + this.itemOrigin : 'MODAL_ORDER';
        const returnValue: IBasketModal = {
            color: this.mainStore.variantStore.selectedColor.code.toString(),
            colorName: this.mainStore.variantStore.selectedColor.name,
            imageUrl: this.mainStore.dataStore.mixedMedia.image.fullPath,
            quantity: articleCount,
            salesVariantKey: this.mainStore?.variantStore.selectedSize?.salesArticleVariantKey,
            size: this.mainStore?.variantStore.selectedSize?.size.name,
            title: this.mainStore.dataStore.orderModule.articleInfo.title,
            maxQuantityPerOrder: this.mainStore.dataStore.orderModule.maxQuantityPerOrder,
            quantityUnit:
                this.mainStore.dataStore.orderModule.maxQuantityPerOrder > 1
                    ? this.mainStore.dataStore.orderModule.articleInfo.packagingInformation.quantityUnit.plural
                    : this.mainStore.dataStore.orderModule.articleInfo.packagingInformation.quantityUnit.singular,
            model: this.mainStore.variantStore.selectedModel.code.toString(),
            modelLabel: this.mainStore.variantStore.selectedModel?.label,
            itemOrigin: basketModalItemOrigin,
            nativeNavKeyPath: this.mainStore.dataStore.breadcrumb.category.navigationKeyPath,
            salesArticleNo: this.mainStore.dataStore.orderModule.articleInfo.salesArticleNo,
            grossPrice: this.mainStore.dataStore.orderModule.articlePrice.basicPrice.grossValue.toString(),
            netPrice: this.mainStore.dataStore.orderModule.articlePrice.basicPrice.netValue.toString(),
            masterArticleNo: this.mainStore.dataStore.orderModule.articleInfo.masterArticleNo.toString(),
            isStraussBrand: this.mainStore.dataStore.orderModule.articleInfo?.brand ?
                this.mainStore.dataStore.orderModule.articleInfo.brand.toLowerCase() === this.esBrand : false,
            scrollPosition: this.mainStore.initialScrollPosition,
            categoryName: this.mainStore.dataStore.breadcrumb.category.name,
            currencyISO: this.mainStore.dataStore.orderModule.articlePrice.currency.isoCode,
        };
        
        if (articleCount > 1) {
            const scaledPrices = this.mainStore.dataStore.orderModule.articlePrice.scalePrices;
            let returnPrice: IPrice;
            for (let i = 0; i < scaledPrices.length; i++) {
                const price = scaledPrices[i];
                if (articleCount >= price.quantity)
                    returnPrice = price.price;
            }
            returnValue.scalePrice = returnPrice;
        }

        return returnValue;
    }
}

interface IBasketModal {
    title: string;
    color: string;
    colorName?: string;
    size: string;
    model: string;
    modelLabel?: string;
    imageUrl: string;
    salesVariantKey: string;
    quantity: number;
    maxQuantityPerOrder?: number;
    quantityUnit: string;
    itemOrigin: string;
    nativeNavKeyPath: string;
    salesArticleNo?: string;
    grossPrice?: string;
    netPrice?: string;
    scalePrice?: IPrice;
    isStraussBrand?: boolean;
    masterArticleNo: string;
    scrollPosition?: number;
    categoryName?: string;
    currencyISO?: string;
}

export default OrderModuleStore;
