import React, { Component } from 'react';
import { IBookmarkButtonProps, BookmarkIntent } from '../types.d';
import BookmarkIcon from '../icons/bookmark_icon';
import styles from './BookmarkButton.scss';
import  './CustomBookmarkButton.scss';
import legacyStyles from './BookmarkButton-legacy.scss';
import classNames from 'classnames';
import { isTablet } from 'react-device-detect';

class BookmarkButton extends Component<IBookmarkButtonProps> {
    private elementRef: React.RefObject<HTMLAnchorElement>;

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.elementRef = React.createRef<HTMLAnchorElement>();
    }

    private handleKeyDown(event) {
        if(event){
            event.preventDefault();
        }

        if (event.key === ' ' || event.key === 'Enter') {
            const payload: BookmarkIntent = {
                origin: this.props,
                focusMethod: 'keyboard'
            };
            window.shell.publishTo('ESCID.ESPP.Bookmark.ButtonWasClicked', payload);
        }

        return false;
    }

    private handleClick(event) {
        if(event){
            event.preventDefault();
        }

        const payload: BookmarkIntent = {
            origin: this.props
        };
        window.shell.publishTo('ESCID.ESPP.Bookmark.ButtonWasClicked', payload);

        return false;
    }

    private setFocusableElement(element: HTMLButtonElement) {
        if (element) {
            if (!element.hasAttribute('focusable'))
                element.toggleAttribute('focusable');
            element.setAttribute('order', '0');
        }
    }

    render() {
        const make_sure_this_is_referenced = legacyStyles.legacy_ordermodal_bookmark_add_button_outer;
        const showIcon = this.props.isRedesignOrderModal && (!this.props.isMobile && !isTablet);
        return showIcon ?
            <button
                className={styles.bookmark_add_button_icon}
                onClick={e => this.handleClick(e)}
                onKeyDown={this.handleKeyDown}
                data-testid="bookmark-add-button"
                title={this.props.localization.bookmark +'.'}
                ref={(el) => this.setFocusableElement(el)}
            >
                <BookmarkIcon />
                <span>{this.props.localization.bookmark}</span>
            </button> :
            <a className={'pdp-invisible_container'} ref={this.elementRef}>
                <button aria-roledescription={make_sure_this_is_referenced}
                    className={classNames(styles.bookmark_add_button,
                        'bookmark-add-button pdp-compareShareBookmark_button_style'
                    )}
                    onClick={e => this.handleClick(e)}
                    onKeyDown={this.handleKeyDown}
                    data-testid="bookmark-add-button"
                    title={this.props.localization.bookmark +'.'}>
                    <BookmarkIcon />
                    <span>{this.props.localization.bookmark}</span>
                </button>
        </a>;
    }
}

export default BookmarkButton;
