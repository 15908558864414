/* eslint-disable react/prop-types */
import * as React from 'react';

import styles from './modalcontainer.scss';
import '../common/customStyles.scss';
import { IBasketModalData, IProps, IAMSResponseData, ICrossSell, IBasketModalDataForLogoCreator } from '../../types/properties';
import axios from 'axios';
import SelfServiceBanModal from '../selfServiceBanModal/selfServiceBanModal';
import MaxAmountModal from '../maxAmountModal/maxAmountModal';
import BasketModal from '../basketmodal/basketmodal';
import SelfServiceBanForPrivateModal from '../selfServiceBanForPrivateModal/selfServiceBanForPrivateModal';
import { ScrollLock } from '../helper/scrollLock';
import { ActionHelper } from '../helper/ActionHelper';
import { EsAppHelper } from '../helper/esAppHelper';
import { IActionControlEventData } from '../action-control';

/* eslint-disable no-var, @typescript-eslint/no-explicit-any */
declare var _paq: any;
declare var _fbq: any;
enum BasketErrorCodes {
    MaxAmount = 'MaxItemQuantityPerOrderExceeded',
    SelfServiceBanNotAllowedForPrivateCustomer = 'SelfServiceBanNotAllowedForPrivateCustomer',
    SelfServiceBanNotConfirmed = 'SelfServiceBanNotConfirmed'
}

const basketModalContainerId = 'basket_modal_container';

interface IState {
    showModal: boolean;
    modalContent: JSX.Element;
    priceMode: string;
    isLoading: boolean;
}

export default class ModalContainer extends React.Component<IProps, IState> {
    private addItemEndpoint = 'api/basket/basket/add-item'
    private AMSEndpoint = 'api/articlemetric/crosssellingteaser?salesArticleVariantKey=';
    private readonly scrollLock = new ScrollLock(basketModalContainerId);
    private readonly actionHelper = ActionHelper.instance;
    public readonly esAppHelper = new EsAppHelper();
    private readonly apiPrefix;
    private previouslySelectedElement: Element;

    constructor(props: IProps) {
        super(props);
        this.apiPrefix = props.magicLink;

        this.state = {
            showModal: false,
            modalContent: null,
            priceMode: this.getPriceMode(),
            isLoading: false,
        }

        this.handleModalClose = this.handleModalClose.bind(this);
    }

    componentDidMount() {
        if (typeof window !== 'undefined' && window.shell) {
            window.shell.subscribeTo(
                'ESPP.BasketModal.Open',
                payload => {
                    this.handleBasketModalOpen(payload)
                },
                'BasketModal'
            );

            window.shell.subscribeTo(
                'ESPP.BasketModal.Open.LogoCreator',
                payload => {
                    this.handleBasketModalOpenForLogoCreator(payload)
                },
                'BasketModal'
            );

            window.shell.subscribeTo(
                'PriceModeChanged',
                payload => {
                    if (typeof payload === 'string' && payload.length > 0)
                        this.setState({ priceMode: payload });
                },
                'BasketModal'
            );

            window.shell.subscribeTo(
                'modal.closed',
                payload => {
                    if (this.state.showModal && payload === 'quickOrderModal-addToBasket')
                        this.scrollLock.lock();
                },
                'basketModal'
            );
        }
    }

    private handleBasketModalOpen(data: IBasketModalData) {
        if (!this.state.isLoading) {
            this.setState({ isLoading: true });
            this.addToBasket(data).finally(() => {
                this.setState({ isLoading: false })
            });

            this.previouslySelectedElement = document.activeElement;
        }
    }

    private handleBasketModalOpenForLogoCreator(data: IBasketModalDataForLogoCreator) {
        if (!this.state.isLoading) {
            this.setState({ isLoading: true });
            this.openForLogoCreator(data);
        }
    }

    private getPriceMode(): string {
        if (this.props.priceMode && this.props.priceMode.length > 0)
            return this.props.priceMode
        else if (typeof document !== 'undefined') {
            const html = document.getElementsByTagName('html')[0];
            if (html.classList.contains('current-pricemode-gross'))
                return 'Gross';
            else if (html.classList.contains('current-pricemode-net'))
                return 'Net';
            else
                return '';
        } else
            return '';
    }

    private confirmSelfServiceBan(data: IBasketModalData) {
        if (!this.state.isLoading) {
            this.setState({ isLoading: true });
            this.addToBasket(data, true).finally(() => {
                this.setState({ isLoading: false })
            });
        }
    }

    private willShowBasketModal(isSuccessAdd: boolean) {
        if (typeof window != 'undefined' && window.shell)
            window.shell.publishTo(
                'ESPP.BasketModal.WillOpen',
                {
                    successAdd: isSuccessAdd
                }
            );
        window.shell.publishTo('ESPP.OrderModal.Close', null);
    }

    private failShowBasketModal(reason) {
        if (typeof window != 'undefined' && window.shell) {
            let error = null;
            if (reason)
                error = { error: reason };

            window.shell.publishTo(
                'ESPP.BasketModal.Failed', error);
        }
    }

    private handleModalClose() {
        window.shell.tabNav.focus(this.previouslySelectedElement);

        if (this.props.isEsApp)
            this.esAppHelper.navigationWheel.updateWheel();

        this.setState({ showModal: false });

        this.scrollLock.unlock();
        this.esAppHelper.navigationWheel.show();

        window.shell.publishTo(
            'ESPP.BasketModal.Close', null
        );
    }

    private buildModal(responseData, basketModalData: IBasketModalData, amsResponse?: IAMSResponseData) {
        setTimeout(() => this.scrollLock.lock(basketModalData.scrollPosition), 200);
        setTimeout(() => this.esAppHelper.navigationWheel.hide(), 200);

        if (responseData.successful === true) {
            this.setState({
                modalContent:
                    <BasketModal
                        onIsOpenChange={this.handleModalClose}
                        data={basketModalData}
                        localization={this.props.localization}
                        priceMode={this.state.priceMode}
                        amsResponse={amsResponse}
                        cdnHost={this.props.cdnHost}
                    />
            });
            this.willShowBasketModal(true);
            this.setState({ showModal: true });
            this.reloadBasketFlyout();

            const actionEventData: IActionControlEventData = {
                actionSubject: basketModalData.quantity + '/' + basketModalData.masterArticleNo + '/' + basketModalData.salesVariantKey,
                actionType: 'AddToBasket'
            };

            this.actionHelper.trackActionControlEventCallback(actionEventData);
        }
        else {
            if (responseData.errorCode == BasketErrorCodes.MaxAmount) {
                this.setState({
                    modalContent: <MaxAmountModal
                        onIsOpenChange={this.handleModalClose}
                        errorMessage={responseData.errorMessage}
                        maxQuantityOrderTitle={this.props.localization.maxQuantityOrderTitle} />
                });
                this.willShowBasketModal(false);
                this.setState({ showModal: true });
            }
            if (responseData.errorCode == BasketErrorCodes.SelfServiceBanNotConfirmed) {
                this.setState({
                    modalContent: <SelfServiceBanModal onIsOpenChange={this.handleModalClose}
                        onConfirm={() => this.confirmSelfServiceBan(basketModalData)} localization={this.props.localization} />
                });
                this.willShowBasketModal(false);
                this.setState({ showModal: true });
            }
            if (responseData.errorCode == BasketErrorCodes.SelfServiceBanNotAllowedForPrivateCustomer) {
                this.setState({
                    modalContent: <SelfServiceBanForPrivateModal onIsOpenChange={this.handleModalClose}
                        localization={this.props.localization} />
                });
                this.willShowBasketModal(false);
                this.setState({ showModal: true });
            }
        }
    }

    private buildModelForLogoCreator(basketModalData: IBasketModalDataForLogoCreator, amsResponse?: IAMSResponseData) {
        this.scrollLock.lock();
        setTimeout(() => this.esAppHelper.navigationWheel.hide(), 200);

        this.setState({
            modalContent:
                <BasketModal
                    onIsOpenChange={this.handleModalClose}
                    data={null}
                    dataForLC={basketModalData}
                    localization={this.props.localization}
                    priceMode={this.state.priceMode}
                    amsResponse={amsResponse}
                    cdnHost={this.props.cdnHost}
                />
        });
        this.willShowBasketModal(true);
        this.setState({ showModal: true });
    }

    private trackAddedArticle(data: IBasketModalData) {
        if (!this.props.hasPiwikTracking && typeof _paq === 'undefined')
            return;

        const priceString = data.scalePrice ? data.scalePrice.netValue : data.netPrice;
        const trackedData = [
            {
                sku: data.salesArticleNo,
                name: data.title,
                category: data.nativeNavKeyPath.split('/'),
                price: priceString,
                quantity: data.quantity,
                brand: data.isStraussBrand ? 'Strauss' : 'other Brand',
                variant: data.salesVariantKey,
                customDimensions: {
                    '1': data.masterArticleNo,
                    '2': data.color && data.color.length > 0 && data.color != '0' ? data.color : '',
                    '3': data.size,
                    '4': data.model && data.model.length > 0 && data.model != '0' ? data.model : '',
                    '5': data.itemOrigin || ''
                }
            }
        ];

        if (typeof _paq !== 'undefined')
            _paq.push(['ecommerceAddToCart', trackedData]);
    }

    public metaTrackAddToCart(data: IBasketModalData) {
        if (!this.props.hasMetaTracking && typeof _fbq === 'undefined')
            return;

        const priceString = data.scalePrice ? data.scalePrice.grossValue : data.grossPrice;
        const value = parseFloat(priceString) * data.quantity;

        const trackedData = {
            contents: [
                {
                    id: data.salesVariantKey,
                    quantity: data.quantity,
                }
            ],
            content_type: 'product',
            value: value.toFixed(2),
            currency: data.currencyISO,
        };

        if (typeof _fbq !== 'undefined')
            _fbq.push(['track', 'AddToCart', trackedData]);
    }

    public metaTrackAddToCartFromLC(data: IBasketModalDataForLogoCreator) {
        if (!this.props.hasMetaTracking && typeof _fbq === 'undefined')
            return;

        const contents = data.sizesWithPrice.map(size => {
            return { id: size.savKey, quantity: size.quantity };
        });
        let value = 0;
        let totalQuantity = 0;

        data.sizesWithPrice.forEach(size => {
            value += size.grossPrice * size.quantity;
            totalQuantity += size.quantity;
        });

        const reportingPrice = data.reporting.price.value * totalQuantity;
        value += reportingPrice;

        const trackedData = {
            contents,
            content_type: 'product',
            value: value.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            currency: data.reporting.price.currency,
        };

        if (typeof _fbq !== 'undefined')
            _fbq.push(['track', 'AddToCart', trackedData]);
    }

    private trackAddedArticleFromLC(data: IBasketModalDataForLogoCreator) {
        if (!this.props.hasPiwikTracking && typeof _paq === 'undefined')
            return;
        const reportingPrice = data.reporting.price.value.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        data.sizesWithPrice.forEach(size => {
            const keys = size.savKey.split('-');
            const trackedData = [
                {
                    sku: keys[0],
                    name: data.title,
                    category: data.nativeNavKeyPath.split('/'),
                    price: size.netPrice,
                    quantity: size.quantity,
                    brand: data.isStraussBrand ? 'Strauss' : 'other Brand',
                    variant: size.savKey,
                    customDimensions: {
                        '1': data.masterArticleNumber.toString(),
                        '2': data.color && data.color.length > 0 && data.color != '0' ? data.color : '',
                        '3': size.label,
                        '4': data.model && data.model.length > 0 && data.model != '0' ? data.model : '',
                        '5': data.itemOrigin,
                        '6': data.reporting.customizations,
                        '7': reportingPrice,
                    }
                }
            ];

            if (typeof _paq !== 'undefined')
                _paq.push(['ecommerceAddToCart', trackedData]);
        });
    }

    private async addToBasket(data: IBasketModalData, selfServiceBan = false) {
        const finalEndpoint = this.apiPrefix
            + this.AMSEndpoint + data.salesVariantKey;
        const basketPromise = axios.post(this.apiPrefix + this.addItemEndpoint, {
            salesArticleVariantKey: data.salesVariantKey,
            quantity: data.quantity,
            selfServiceBanConfirmed: selfServiceBan,
            itemOrigin: data.itemOrigin,
            nativeNavKeyPath: data.nativeNavKeyPath,
        });
        const AMSPromise = axios.get(`${finalEndpoint}`, { timeout: 3000 });

        if (!selfServiceBan) {
            try {
                const [addToBasketResponse, AMSResponse] = await Promise.allSettled([
                    basketPromise, AMSPromise]);

                if (addToBasketResponse.status === 'fulfilled') {
                    const AMSData: IAMSResponseData = AMSResponse.status === 'fulfilled' ? {
                        crossSellings: AMSResponse.value.data.articles,
                        rootCategory: AMSResponse.value.data.rootCategoryNavigationKey,
                    } : null;
                    if (AMSData != null) {
                        const validCrossSellUnits: ICrossSell[] = AMSData.crossSellings?.filter(
                            unit => !unit.detailsPageLink.endsWith(location.hostname + '/')
                        );
                        AMSData.crossSellings = validCrossSellUnits;
                        AMSData.crossSellings.forEach(crossSelling => { crossSelling.isGlobal = this.props.isGlobal });
                    }
                    this.trackAddedArticle(data);
                    this.metaTrackAddToCart(data);
                    this.buildModal(addToBasketResponse.value.data, data, AMSData);
                } else {
                    this.failShowBasketModal(addToBasketResponse.reason);
                }
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
            }
        } else {
            try {
                const addToBasketResponse = await basketPromise;
                this.buildModal(addToBasketResponse.data, data);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
            }
        }
    }

    private openForLogoCreator(data: IBasketModalDataForLogoCreator) {
        const finalEndpoint = this.apiPrefix
            + this.AMSEndpoint + data.exampleSalesVariantKey;
        axios.get(`${finalEndpoint}`, { timeout: 3000 }).then((response) => {
            const AMSData: IAMSResponseData = response.status === 200 ? {
                crossSellings: response.data.articles,
                rootCategory: response.data.rootCategoryNavigationKey,
            } : null;
            if (AMSData != null) {
                const validCrossSellUnits: ICrossSell[] = AMSData.crossSellings?.filter(
                    unit => !unit.detailsPageLink.endsWith(location.hostname + '/')
                );
                AMSData.crossSellings = validCrossSellUnits;
                AMSData.crossSellings.forEach(crossSelling => { crossSelling.isGlobal = this.props.isGlobal });
            }
            this.buildModelForLogoCreator(data, AMSData);
            if (data.sizesWithPrice && data.sizesWithPrice.length > 0) {
                this.trackAddedArticleFromLC(data);
                this.metaTrackAddToCartFromLC(data);
            }
        }).finally(() => {
            this.setState({ isLoading: false })
        });
    }

    private reloadBasketFlyout() {
        window.shell.publishTo('ESSA.BasketFlyout.BasketItemCountChanged', null);
    }

    render() {
        return (
            <div id={basketModalContainerId} data-testid='basket_modal_container' className={styles.basket_modal_container}>
                {this.state.showModal && this.state.modalContent}
            </div>
        )
    }
}
