import React from 'react';
import { inject, observer } from 'mobx-react';
import styles from './closeButton.scss';
import CrossIcon from '../../../Assets/svg/crossIcon';
import { IInjectedProps } from '../../../types';


const CloseButton = inject('store')(
    observer(({ store, handleIsOpenChange, title }: IInjectedProps & ICloseButtonProps) => {
    function handleCloseClick(evt) {
        evt.preventDefault();
        handleIsOpenChange(false);
    }

    const buttonRef = React.useRef<HTMLButtonElement>();
    React.useEffect(() => {
        if (buttonRef && buttonRef.current) {
            if (store.enableTabNavigation && typeof window !== 'undefined' && window.shell && window.shell.tabNav) {
                store.tabNavTargetOnClose = document.activeElement;
            }

            store.setFocusableElement(buttonRef.current, 1);
            store.setTabNavFocus(buttonRef.current);

            return () => {
                if (store.tabNavTargetOnClose) {
                    store.setTabNavFocus(store.tabNavTargetOnClose);
                }
            };
        }
    }, []);
    
    return (
        <div className={styles.close_button_container}>
            <button className={styles.close_button} onClick={handleCloseClick} data-testid='close_button'
            title={title} ref={buttonRef}>
                <CrossIcon />
            </button>
        </div>
    )
    })
);

interface ICloseButtonProps {
    title: string;
    handleIsOpenChange(isOpen:boolean);
}

export default CloseButton;
