import React from 'react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import StatusIcon from '../../icons/statusIcon';
import styles from './deliveryTime.scss';
import ModalWrapper from '../../modalWrapper/modalWrapper';
import { IInjectedProps } from '../../../types';
import defaultStyles from '../../../styles/defaults.scss';

const StatusIconDefaultProps = {
    statusColor: defaultStyles.dpBlack,
};

export enum AvailabilityState {
    InStock,
    SoonInStock,
}

const DeliveryTime = inject('store')(
    observer(({ store }: IInjectedProps) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const localization = store!.localization;
        const [modalOpen, setModalOpen] = React.useState(false);

        const handleDeliveryTimeClick = React.useCallback((open) => {
            setModalOpen(open);
        }, []);

        return (
            <div className={classNames(styles.delivery_time_container)}>
                <StatusIcon {...StatusIconDefaultProps} statusColor={store?.orderModuleStore.deliveryTimeStatusColor} />
                <span
                    data-testid='om-delivery_time'
                    className={classNames(styles.delivery_time_text, { [styles.isGlobal]: store.isGlobal })}
                    onClick={!store.isGlobal ? () => handleDeliveryTimeClick(true) : null}
                    ref={store.setFocusableElement}
                >
                    {store?.orderModuleStore.deliveryTimeString}
                </span>
                <ModalWrapper
                    isOpen={modalOpen}
                    title={localization.deliveryTime}
                    onClose={() => handleDeliveryTimeClick(false)}
                    content={
                        <div
                            className={styles.delivery_time_modal_content}
                            dangerouslySetInnerHTML={{ __html: localization.deliveryTimeText }}
                        />
                    }
                    enableTabNavigation={store.enableTabNavigation}
                />
            </div>
        );
    })
);

export default DeliveryTime;
