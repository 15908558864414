import React from 'react';
import CrossIcon from '../../Assets/svg/crossIcon';
import styles from '../modal.scss';

// eslint-disable-next-line react/prop-types
export default class ModalWrapper extends React.Component<{ children, title, onClose, closeBtnTitle}> {
    private modalContentRef = React.createRef<HTMLDivElement>();
    private modalHeaderRef = React.createRef<HTMLDivElement>();
    private closeBtnRef = React.createRef<HTMLButtonElement>();

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if(this.modalContentRef && this.modalContentRef.current) {
            this.modalContentRef.current.setAttribute('group', '');
            this.modalContentRef.current.setAttribute('modal', '');
        }
    
        if(this.modalHeaderRef && this.modalHeaderRef.current) {
            this.modalHeaderRef.current.setAttribute('group', '');
        }
    
        if(this.closeBtnRef && this.closeBtnRef.current) {
            this.closeBtnRef.current.setAttribute('focusable', '');

            window.shell.tabNav.focus(this.closeBtnRef.current);
        }
    }

    render() {
        return (
            <>
                <div className={styles.modal_wrapper}>
                    <div className={styles.modal_container}>
                        <div className={styles.modal_content} ref={this.modalContentRef}
                            data-testid='modal_content'>
                            <div className={styles.modal_header} ref={this.modalHeaderRef}
                                data-testid='modal_header'>
                                <span className={styles.modal_title}>{this.props.title}</span>
                                <button className={styles.close_button} data-testid="close_button_header"
                                    title={this.props.closeBtnTitle}
                                    onClick={this.props.onClose} ref={this.closeBtnRef}
                                    tabIndex={0}>
                                    <CrossIcon />
                                </button>
                            </div>
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
