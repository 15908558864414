import React from 'react';
import styles from './notAvailableModal.scss';
import ModalWrapper from '../modalWrapper/modalWrapper';

function NotAvailableModal(props: INotAvailableModal) {
    const handleCloseClick = React.useCallback(() => {
        props.onClose(false);
    }, []);

    const closeButtonRef = React.useRef<HTMLButtonElement>();
    React.useEffect(() => {
        if (closeButtonRef && closeButtonRef.current) {
            if (typeof window !== 'undefined' && window.shell && window.shell.tabNav) {
                if (!closeButtonRef.current.hasAttribute('focusable')) {
                    closeButtonRef.current.toggleAttribute('focusable');
                    closeButtonRef.current.setAttribute('order', '2');
                }
            }
        }
    }, []);

    return (
        <ModalWrapper
            isOpen={props.isOpen}
            contentClassNames={styles.not_available_modal_content}
            title={__OMI18n__.note}
            titleClassNames={styles.not_available_modal_title}
            onClose={handleCloseClick}
            content={
                <div data-articleorigin={props.articleOrigin} className={styles.not_available_modal_body}>
                    <div dangerouslySetInnerHTML={{ __html: __OMI18n__.productNotAvailable }} />
                    <button className={styles.close_button} onClick={handleCloseClick} ref={closeButtonRef}>
                        {__OMI18n__.buttonClose}
                    </button>
                </div>
            }
            enableTabNavigation={props.enableTabNavigation}
        />
    );
}

interface INotAvailableModal {
    isOpen: boolean;
    enableTabNavigation?: boolean;
    onClose(isOpen: boolean);
    articleOrigin: string;
}

export default NotAvailableModal;
