import React from 'react';
import style from '../../modal.scss';
import classNames from 'classnames';
import BasketIcon from '../../../Assets/svg/basketIcon';
import { IModalFooter } from '../../../types/properties';
import * as helper from '../../helper/html-helper';
export default class ModalFooter extends React.Component<IModalFooter> {
    private modalFooterRef = React.createRef<HTMLDivElement>();
    private greyBtnRef = React.createRef<HTMLButtonElement>();
    private redBtnRef = React.createRef<HTMLButtonElement>();

    constructor(props) {
        super(props);

        this.closeButtonClick = this.closeButtonClick.bind(this);
    }

    componentDidMount() {
        if (this.modalFooterRef && this.modalFooterRef.current) {
            this.modalFooterRef.current.setAttribute('group', '');
        }

        if (this.greyBtnRef && this.greyBtnRef.current) {
            this.greyBtnRef.current.setAttribute('focusable', '');
            this.greyBtnRef.current.setAttribute('order', '1');
        }

        if (this.redBtnRef && this.redBtnRef.current) {
            this.redBtnRef.current.setAttribute('focusable', '');
            this.redBtnRef.current.setAttribute('order', '2');
        }
    }

    private closeButtonClick() {
        if (this.props.isLC) {
            this.props.onClose();
            if (typeof window != 'undefined' && window.shell)
                window.shell.publishTo('ESPP.BasketModal.OpenLogoCreator', { smakeId: this.props.smakeId });
        } else {
            this.props.onClose();
        }
    }

    render() {
        return (
            <div className={style.modal_footer} ref={this.modalFooterRef} data-testid='modal_footer'>
                <button className={classNames(style.button_container, style.grey)}
                    ref={this.greyBtnRef}
                    data-testid='show_shopping_basket_button' onClick={this.props.onContinue}
                    tabIndex={0}>
                    <div className={style.button_icon}>
                        <BasketIcon />
                    </div>
                    <div className={style.button_label}>
                        {helper.decodeHTML(this.props.localization.goToBasket)}
                    </div>
                </button>
                <button data-testid='continue_shopping_button'
                    id='continue_shopping_button'
                    className={classNames(style.button_container, style.red)}
                    onClick={this.closeButtonClick}
                    ref={this.redBtnRef}
                    tabIndex={0}>
                    <div className={style.button_label}>
                        {
                            this.props.isLC ?
                                helper.decodeHTML(this.props.localization.continuePersonalisation) :
                                helper.decodeHTML(this.props.localization.continueShopping)
                        }
                    </div>
                </button>
            </div>);
    }
}
