import * as React from 'react';
import classNames from 'classnames';
import CrossIcon from '../icons/crossIcon';
import styles from './modalWrapper.scss';

function ModalContent(props: IModalContent) {
    const tabNavTargetOnClose =  React.useRef<Element>(null);
    const contentRef = React.useRef<HTMLDivElement>();
    const closeButtonRef = React.useRef<HTMLDivElement>();

    const contentClassNames = classNames(styles.modal_content,
        typeof props.contentClassNames === 'string' ? props.contentClassNames  : '',
        Array.isArray(props.contentClassNames) ? props.contentClassNames.join(' ')  : '',
    );

    const titleClassNames = classNames(styles.modal_title,
        typeof props.titleClassNames === 'string' ? props.titleClassNames  : '',
        Array.isArray(props.titleClassNames) ? props.titleClassNames.join(' ')  : '',
    );

    React.useEffect(() => {
        if (contentRef && contentRef.current && closeButtonRef && closeButtonRef.current) {
            if (props.enableTabNavigation && typeof window !== 'undefined' && window.shell && window.shell.tabNav) {
                if (!contentRef.current.hasAttribute('group')) {
                    contentRef.current.toggleAttribute('group');
                }

                if (!contentRef.current.hasAttribute('modal')) {
                    contentRef.current.toggleAttribute('modal');
                }

                if (!closeButtonRef.current.hasAttribute('focusable')) {
                    closeButtonRef.current.toggleAttribute('focusable');
                    closeButtonRef.current.setAttribute('order', '1');
                }

                if (tabNavTargetOnClose) {
                    tabNavTargetOnClose.current = document.activeElement;
                }
                window.shell.tabNav.focus(closeButtonRef.current);
            }
        }

        return () => {
            if (props.enableTabNavigation && typeof window !== 'undefined' && window.shell && window.shell.tabNav &&
                tabNavTargetOnClose && tabNavTargetOnClose.current) {
                window.shell.tabNav.focus(tabNavTargetOnClose.current);
            }
        }
    }, []);

    return (
        <div className={contentClassNames} ref={contentRef}>
            <div className={styles.modal_header}>
                <div className={titleClassNames}>
                    <div
                        className={styles.title}
                        dangerouslySetInnerHTML={{ __html: props.title }}
                    />
                    {props.subtitle && (
                        <div
                            className={styles.subtitle}
                            dangerouslySetInnerHTML={{ __html: props.subtitle }}
                        />
                    )}
                </div>

                <div
                    className={styles.close_button}
                    data-testid='close_button_header'
                    onClick={props.onClose}
                    ref={closeButtonRef}
                >
                    <CrossIcon />
                </div>
            </div>
            <div className={styles.modal_body}>{props.content}</div>
        </div>
    );
}

interface IModalContent {
    contentClassNames?: string | string[];
    title: string;
    titleClassNames?: string | string[];
    subtitle?: string;
    content: React.ReactNode;
    enableTabNavigation?: boolean;

    onClose();
}

function ModalWrapper(props: IModalWrapper) {
    if (!props.isOpen) {
        return null;
    }

    return (
        <>
            <div className={styles.modal_wrapper}>
                <div className={styles.modal_container}>
                    <ModalContent
                        contentClassNames={props.contentClassNames}
                        title={props.title}
                        subtitle={props.subtitle}
                        titleClassNames={props.titleClassNames}
                        content={props.content}
                        onClose={props.onClose}
                        enableTabNavigation={props.enableTabNavigation}
                    />
                </div>
            </div>
        </>
    );
}

export default ModalWrapper;

interface IModalWrapper {
    isOpen: boolean;
    contentClassNames?: string | string[];
    title: string;
    titleClassNames?: string | string[];
    subtitle?: string;
    content: React.ReactNode;
    enableTabNavigation?: boolean;

    onClose();
}
